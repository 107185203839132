import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Paper = makeShortcode("Paper");
const TextCustom = makeShortcode("TextCustom");
const Img = makeShortcode("Img");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "encourage-good-health-habits"
    }}>{`Encourage good health habits`}</h1>
    <p>{`Parents can reduce their teenager’s risk of developing depression and clinical anxiety by helping them to develop good lifestyle habits in FIVE areas.`}</p>
    <Grid container mdxType="Grid">
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <Paper mdxType="Paper">
      <TextCustom customColor="blue" align="center" variant="h6" mdxType="TextCustom">
        Healthy diet
      </TextCustom>
      <Img src="/images/m7/32.svg" alt="diet" mdxType="Img" />
    </Paper>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <Paper mdxType="Paper">
      <TextCustom customColor="blue" align="center" variant="h6" mdxType="TextCustom">
        Daily exercise
      </TextCustom>
      <Img src="/images/m7/33.svg" alt="exercise" mdxType="Img" />
    </Paper>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <Paper mdxType="Paper">
      <TextCustom customColor="blue" align="center" variant="h6" mdxType="TextCustom">
        No drugs or alcohol
      </TextCustom>
      <Img src="/images/m7/36.svg" alt="drugs" mdxType="Img" />
    </Paper>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <Paper mdxType="Paper">
      <TextCustom customColor="blue" align="center" variant="h6" mdxType="TextCustom">
        Good sleep habits
      </TextCustom>
      <Img src="/images/m7/34.svg" alt="sleep" mdxType="Img" />
    </Paper>
  </Grid>
  <Grid item xs={12} sm={6} md={4} mdxType="Grid">
    <Paper mdxType="Paper">
      <TextCustom customColor="blue" align="center" variant="h6" mdxType="TextCustom">
        Healthy screen use
      </TextCustom>
      <Img src="/images/shared/55.svg" alt="health" mdxType="Img" />
    </Paper>
  </Grid>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      